<div class="container">
  <div class="business-modal-container">
    <span class="close-modal" id="close-modal-button" (click)="modalController.dismiss()">&#10005;</span>
    <h2 class="header">2 ways to get started with Poplin for Business</h2>
    <div class="options-container">
      <div class="option">
        <h3 class="head">Self-Serve</h3>
        <p class="text">
          Place your first order in minutes. Perfect for pickups under 300 pounds per week. Multiple locations no
          problem.
        </p>
        <ion-button (click)="signUp($event)" id="business-signup-button" class="button" color="dark" expand="block"
          >SIGN UP</ion-button
        >
      </div>
      <div class="option">
        <h3 class="head">Request a Consultation</h3>
        <p class="text">Got over 300 pounds per week? Have some special needs? Let's get you set up.</p>
        <ion-button
          (click)="contactSales()"
          id="business-contact-sales-button"
          class="button"
          color="dark"
          expand="block"
          >CONTACT SALES</ion-button
        >
      </div>
    </div>
  </div>
</div>
