import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule],
  selector: 'app-business-signup',
  templateUrl: './business-signup.component.html',
  styleUrls: ['./business-signup.component.scss'],
})
export class BusinessSignupComponent {
  constructor(public modalController: ModalController) {}

  signUp(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    openCustomerAppWithQueryParams('signup', target.id);
  }

  async contactSales() {
    this.modalController.dismiss({ contactSales: true });
  }
}
