import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AccordionHeaders, Panel } from '../../../interfaces/common';

@Component({
  standalone: true,
  selector: 'app-nested-accordion',
  templateUrl: './nested-accordion.component.html',
  styleUrls: ['./nested-accordion.component.scss'],
  imports: [CommonModule, IonicModule],
  encapsulation: ViewEncapsulation.None,
})
export class NestedAccordionComponent implements AfterViewInit {
  @Input() faqs: boolean;
  @Input() home: boolean;
  @Input() headers: AccordionHeaders = { header: '', subHeader: '' };
  @Input() nested: boolean;
  @Input() content: Panel[] = [{ tab: '', panel: '' }];
  @ViewChildren('button') buttons: QueryList<ElementRef>;

  ngAfterViewInit() {
    this.buttons.forEach(button => {
      button.nativeElement.setAttribute('tabindex', '-1');
      button.nativeElement.setAttribute('aria-hidden', 'true');
    });
  }

  toggleTab(event: MouseEvent): void {
    const header = (event.target as HTMLElement).closest('.accordion-header') as HTMLElement;
    const content = header.nextElementSibling as HTMLElement;
    const isOpening = !content.classList.contains('open');
    this.replaceBrWithSpaces(content);
    content.classList.toggle('open');
    header.classList.toggle('open');

    const icon = header.querySelector('.toggle-icon') as HTMLElement;
    if (isOpening) {
      icon.classList.add('open');
      header.setAttribute('tabindex', '1');
      header.setAttribute('aria-hidden', 'false');
      header.setAttribute('aria-expanded', 'false');
      header.setAttribute('aria-controls', 'service-accordion-content');
    } else {
      icon.classList.remove('open');
      header.setAttribute('tabindex', '-1');
      header.setAttribute('aria-hidden', 'true');
      header.setAttribute('aria-expanded', 'true');
      header.setAttribute('aria-controls', 'service-accordion-content');
    }

    if (!isOpening) {
      const nestedContent = content.querySelectorAll('.accordion-content');
      nestedContent.forEach(element => {
        element.classList.remove('open');
        const prevElement = element.previousElementSibling;
        if (prevElement) {
          const nestedIcons = prevElement.querySelectorAll('.toggle-icon');
          nestedIcons.forEach(nestedIcon => nestedIcon.classList.remove('open'));
        }
      });
    }
  }

  replaceBrWithSpaces(element: HTMLElement) {
    const paragraphs = element.querySelectorAll('p');
    paragraphs.forEach(p => {
      p.innerHTML = p.innerHTML.replace(/<br\s*\/?>/gi, ' ');
      p.innerHTML = p.innerHTML.replace(/<ol>/gi, '<ul>').replace(/<\/ol>/gi, '</ul>');
    });
  }

  genUniqueId(index: number, element: HTMLElement) {
    if (!element) {
      return '';
    }

    if (!element.id) {
      const randomNum = Math.floor(Math.random() * 100000);
      return `nested-accordion-${index}-` + randomNum + '-button';
    }
    return element.id;
  }
}
