import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-clothes-protection',
  templateUrl: './clothes-protection.component.html',
  styleUrls: ['./clothes-protection.component.scss'],
})
export class ClothesProtectionComponent {
  @Input() business: boolean;
}
