import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LazyImgDirective } from 'src/app/directives/image.directive';

@Component({
  standalone: true,
  selector: 'app-featured-on',
  templateUrl: './featured-on.component.html',
  styleUrls: ['./featured-on.component.scss'],
  imports: [CommonModule, LazyImgDirective],
})
export class FeaturedOnComponent {}
