<app-banner></app-banner>
<h1 class="sr-only">Poplin</h1>
<app-promo-content
  [business]="true"
  [content]="heroContent"
  [classes]="heroClasses"
  [bkgImg]="heroImg"
  [hero]="true"
  [showGetStarted]="true"
  [showButton]="true"></app-promo-content>
<app-check-your-area [business]="true"></app-check-your-area>
<app-promo-content [content]="promoTwoContent" [classes]="promoTwoClasses" [bkgImg]="promoTwoImg"></app-promo-content>
<app-pro-wash [byLine]="byLine" [showGetStarted]="true" [business]="true"></app-pro-wash>
<app-love-language [business]="true"></app-love-language>
<app-preferences [business]="true"></app-preferences>
<app-clothes-protection [business]="true"></app-clothes-protection>
<app-testimonials [business]="true"></app-testimonials>
<app-featured-on></app-featured-on>
<app-promo-content
  [content]="promoThreeContent"
  [classes]="promoThreeClasses"
  [bkgImg]="promoThreeImg"
  [showButton]="true"
  [showGetStarted]="true"></app-promo-content>
<app-nested-accordion [content]="faqs" [faqs]="true" [headers]="faqHeader"></app-nested-accordion>
