export const environment = {
  version: '4.00.0',
  production: true,
  useEmulators: false,
  apiPath: 'https://prod-10qzppbp.uc.gateway.dev',
  apiToken: 'AIzaSyBuboEJt8Lt4XoCe-6sDWLcS8kh4l4iXWI',
  firebase: {
    apiKey: 'AIzaSyCL_QcXwq2sY0ZiLBT7rBlMpVQ9mcMpGks',
    authDomain: 'laundry-llama.firebaseapp.com',
    databaseURL: 'https://laundry-llama.firebaseio.com',
    projectId: 'laundry-llama',
    storageBucket: 'laundry-llama.appspot.com',
    locationId: 'us-central',
    messagingSenderId: '79996270453',
    appId: '1:79996270453:web:95ee565b95725102e4853c',
    measurementId: 'G-7MBFYBXGT5',
  },
  contentful: {
    spaceId: 'ummby9ihu4il',
    accessToken: 'SXLUvDJr8MPnZCRkWRfW8aQE9ASh4bxEQaYwoOZzjGE',
    environment: 'master',
  },
  apps: {
    customer: 'https://app.poplin.co/',
    laundryPro: 'https://laundry-pro.poplin.co/',
  },
  dynamicLinks: {
    customer: 'https://link.poplin.co/app',
    laundryPro: 'https://link.poplin.co/app-lp',
  },
  googleTagManager: {
    appId: 'GTM-PPMZ9PHL',
  },
  loginLinks: {
    customer: 'https://app.poplin.co/?originalUrl=https:%2F%2Fpoplin.co&website=true&login=1',
  },
  preSignUp: {
    customer: 'https://app.poplin.co/',
    laundryPro: 'https://laundry-pro.poplin.co/start',
  },
  referralTerms: {
    refTermDoc: 'https://poplin.co/blog/referral-terms',
  },
  statsig: {
    clientKey: 'client-yQJsCOP8ujYYjSQ7tLP4qYa4KpYDiD5ZZCcIM7xCil2',
    experiments: {
      activeTest: 'hero_image',
    },
    params: {
      LayoutType: 'LayoutType',
    },
  },
};
