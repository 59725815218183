import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { PlatformId } from 'src/app/types/common';
import { HomeTestimonial } from '../../../interfaces/common';
import { ProfileComponent } from '../../util/profile/profile.component';
import { CarouselComponent } from '../carousel/carousel.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

@Component({
  standalone: true,
  imports: [CommonModule, CarouselComponent, ProfileComponent],
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements AfterViewInit {
  @Input() business: boolean;
  public isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: PlatformId) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.Trustpilot != null) {
        const trustboxRef = document.getElementById('trustbox');
        window.Trustpilot.loadFromElement(trustboxRef);
      }
    }
  }

  testimonials: HomeTestimonial[] = [
    {
      name: '',
      city: 'Woodbury, CT',
      rating: '',
      showQuote: true,
      quote:
        '“I recommend Poplin to everyone - give yourself some breathing room (and really beautifully folded and nice smelling laundry) just by clicking a button. Best decision ever!”',
      quoteAuthor: 'Alexandra R, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_alexandra.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Wilmington, NC',
      rating: '',
      showQuote: true,
      quote:
        '“Poplin has allowed me to reclaim my weekends rather than spending it doing laundry. It is one of the best gifts I have given myself.”',
      quoteAuthor: 'Heather T, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_heather-1.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Spring, TX',
      rating: '10/10',
      showQuote: true,
      quote:
        '“As a busy mom with kids in activities and a husband who travels frequently, I use it every couple months. When I tell friends about the service and the reasonable cost - many agree that this “is a game changer!”',
      quoteAuthor: 'Nysha C, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_nysha.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Bolingbrook, IL',
      rating: '',
      showQuote: true,
      quote:
        '“If you’ve ever wished laundry would just disappear & come back washed and folded, you seriously need to try Poplin! Worth every penny. Seriously the best thing I ever budgeted for!”',
      quoteAuthor: 'Soledad C, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_soledad.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Porter, TX',
      rating: '',
      showQuote: true,
      quote:
        '“Laundry is the last thing I have time for. Poplin has made my life so much easier as a working mom. Having my laundry come back clean and tidily folded in the span of a single day is such an affordable luxury.”',
      quoteAuthor: 'Heather B, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_heather-2.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Minneapolis, MN',
      rating: '',
      showQuote: true,
      quote:
        '“Poplin has taken the on-going effort of routine laundry out of our lives and made it painless - the savings in time, supplies and constant frustration makes it a no-brainer for us.”',
      quoteAuthor: 'Mark R, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_mark.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Jackson, TN',
      rating: '',
      showQuote: true,
      quote:
        "“I work 14 hours a day and don't have time to keep my laundry done, so I call Poplin! The prices are great and the services are beyond wonderful. I love Poplin!”",
      quoteAuthor: 'Hope P, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_customer_hope.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'St. Petersburg, FL',
      rating: '',
      showQuote: true,
      quote:
        "“I am a widow, live alone and I have mobility issues. Doing laundry has always been a lot of trouble for me. Finding Poplin has been a godsend for me—it's such a relief. I tell all my friends about Poplin.”",
      quoteAuthor: 'Kellie B, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_kellie.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: 'Chelmsford, MA',
      rating: '',
      showQuote: true,
      quote:
        '“I use Poplin for my laundry now.  They pick up one day and bring back the next. They do an excellent job.”',
      quoteAuthor: 'Joy T, Poplin Client',
      picture: 'assets/images/customer-testimonials-home/poplin_professional-laundry-service_joy.webp',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
  ];
  businessTestimonials: HomeTestimonial[] = [
    {
      name: '',
      city: '',
      rating: '',
      showQuote: true,
      quote:
        '“We have 1500 rentals in 33 cities. Before Poplin, we had 33 laundry vendors. It was messy & quality was unpredictable. We transitioned 13 markets to Poplin. The quality is consistent & operationally it’s simple. I love simple.”',
      quoteAuthor: 'Jesse DePinto - CEO & Co-Founder at Frontdesk',
      picture: 'assets/images/business/business1.webp',
      amount: '40,000lbs laundry/month',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: '',
      rating: '',
      showQuote: true,
      quote:
        '“We own 100 short term rental properties in 4 markets. We’re growing & laundry is a pain-point. With Poplin, our operations are streamlined. Poplin is consistent & cost-effective. As we grow, Poplin grows with us.”',
      quoteAuthor: 'Kasey Conquest - Operations Manager at TBFS Investments LLC',
      picture: 'assets/images/business/business3.webp',
      amount: '30,000lbs laundry/month',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: '',
      rating: '',
      showQuote: true,
      quote:
        '“Having laundry operations streamlined is key for us. Poplin helps us with this across the U.S. Focus is everything, and Poplin’s focus on laundry allows us to focus on guest-ready homes.”',
      quoteAuthor: 'Jonathan Wicks - Founder of Well & Good Property Services',
      picture: 'assets/images/business/business2.webp',
      amount: '60,000lbs laundry/month',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
    {
      name: '',
      city: '',
      rating: '',
      showQuote: true,
      quote:
        '“Our laundry always comes back clean and neatly folded, and our Laundry Pro’s even includes a little bow on the bag which is such a great touch! We love Poplin! Highly recommend.”',
      quoteAuthor: 'Dr. Brett Florie - CEO of Hydration Room',
      picture: 'assets/images/business/business4.webp',
      amount: '',
      date: '',
      showProBadge: false,
      loadNumber: 24,
      showName: false,
    },
  ];
}
