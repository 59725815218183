import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoveLanguageTestimonial } from '../../../interfaces/common';
import { CarouselComponent } from '../../common/carousel/carousel.component';
import { ProfileComponent } from '../../util/profile/profile.component';

@Component({
  standalone: true,
  imports: [CommonModule, CarouselComponent, ProfileComponent],
  selector: 'app-love-language',
  templateUrl: './love-language.component.html',
  styleUrls: ['./love-language.component.scss'],
})
export class LoveLanguageComponent {
  @Input() business: boolean;

  testimonials: LoveLanguageTestimonial[] = [
    {
      name: 'Ashley A.',
      city: 'Columbia, SC',
      rating: '10/10',
      showQuote: true,
      quote: '“I absolutely love making my customers happy. I try to go above and beyond for them!”',
      quoteAuthor: '',
      picture: 'assets/images/laundry-pro-testimonials-home/poplin_laundry-professional_ashley.webp',
      date: 'June 2022',
      showProBadge: true,
      loadNumber: 24,
    },
    {
      name: 'Maria P.',
      city: 'South Elgin, IL',
      rating: '10/10',
      showQuote: true,
      quote:
        '“I love serving Poplin customers, making a difference in their lives by taking a load off their schedules, and helping those who can’t do laundry.”',
      quoteAuthor: '',
      picture: 'assets/images/laundry-pro-testimonials-home/poplin_laundry-professional_maria.webp',
      date: 'July 2022',
      showProBadge: true,
      loadNumber: 24,
    },
    {
      name: 'Janet S.',
      city: 'Colombus, OH',
      rating: '10/10',
      showQuote: true,
      quote:
        '“I am so grateful for my amazing, regular customers, who are so kind and appreciative of the service I provide!”',
      quoteAuthor: '',
      picture: 'assets/images/laundry-pro-testimonials-home/poplin_laundry-professional_janet.webp',
      date: 'Jan 2022',
      showProBadge: true,
      loadNumber: 24,
    },
    {
      name: 'Monique B.',
      city: 'Dallas, TX',
      rating: '10/10',
      showQuote: true,
      quote: "“My Poplin customers are the best. They've become a part of my life!”",
      quoteAuthor: '',
      picture: 'assets/images/laundry-pro-testimonials-home/poplin_laundry-professional_queen.webp',
      date: 'Mar 2022',
      showProBadge: true,
      loadNumber: 24,
    },
    {
      name: 'Megan B.',
      city: 'Broomfield, CO',
      rating: '10/10',
      showQuote: true,
      quote:
        '“I love helping people with something that can be very overwhelming and tedious. Everyone feels better with clean clothes!”',
      quoteAuthor: '',
      picture: 'assets/images/laundry-pro-testimonials-home/poplin_laundry-professional_megan.webp',
      date: 'June 2022',
      showProBadge: true,
      loadNumber: 24,
    },
  ];
}
