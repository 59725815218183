import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { PlatformId } from 'src/app/types/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('marquee') marquee: ElementRef;

  private readonly mobileMaxWidth = 767;
  private readonly tabletMaxWidth = 1024;
  private readonly desktopWidth = 1025;
  public isBrowser: boolean;
  private resizeListener!: () => void;

  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: PlatformId) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    this.updateAnimationDuration();
    this.resizeListener = this.renderer.listen('window', 'resize', this.updateAnimationDuration.bind(this));
  }

  ngOnDestroy() {
    this.resizeListener();
  }

  @HostListener('window:resize', ['$event'])
  checkSize() {
    this.updateAnimationDuration();
  }
  updateAnimationDuration() {
    if (isPlatformBrowser(this.platformId)) {
      const screenWidth = window.innerWidth;
      const textLength = this.marquee.nativeElement.textContent.length;
      let animationDuration = 0;
      const previousAnimationDuration = this.marquee.nativeElement.style.animationDuration;
      if (screenWidth <= this.mobileMaxWidth) {
        animationDuration = (textLength * 20) / 1000; // Adjust this value for mobile
      } else if (screenWidth <= this.tabletMaxWidth) {
        animationDuration = (textLength * 40) / 1000; // Adjust this value for tablet
      } else if (screenWidth >= this.desktopWidth) {
        animationDuration = (textLength * 80) / 1000; // Adjust this value for desktop
      }
      if (animationDuration + 's' === previousAnimationDuration) {
        return;
      }
      this.marquee.nativeElement.style.animationDuration = animationDuration + 's';
    }
  }
}
