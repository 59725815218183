import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { ExtraOptions, RouterModule, provideRouter } from '@angular/router';

import { CommonModule } from '@angular/common';
// import { getPerformance, providePerformance } from '@angular/fire/performance';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { routes } from './app.routes';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  initialNavigation: 'enabledBlocking',
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    importProvidersFrom([
      HttpClientModule,
      CommonModule,
      BrowserModule,
      IonicModule.forRoot(),
      RouterModule.forRoot(routes, routerOptions),
    ]),
  ],
};
