import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Event as NavigationEvent, Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Subscription, filter } from 'rxjs';
import { PlatformId } from 'src/app/types/common';
import { environment } from '../../../../environments/environment';
import { BusinessComponent } from '../../../pages/business/business.component';
import { StatsigService } from '../../../services';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('mainHeader', { static: false })
  header: ElementRef;
  menuOpen = false;
  business = false;
  workFromHome: boolean;
  affiliates: boolean;
  HideMenu = false;
  private subscriptions = new Subscription();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: PlatformId,
    private renderer: Renderer2,
    private router: Router,
    private businessComponent: BusinessComponent,
    private statsigService: StatsigService,
    private activatedRoute: ActivatedRoute
  ) {
    const sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlWithoutParamsAndFragments = event.url.replace(/[?#].*$/, '');
        const currentUrlSlug = urlWithoutParamsAndFragments.slice(1);
        switch (currentUrlSlug) {
          case '':
          case 'site-link-1':
          case 'site-link-2':
          case 'site-link-3':
          case 'site-link-4':
          case 'same-day-laundry':
          case 'do-my-laundry':
          case 'apply':
          case 'gift':
          case 'referral':
          case 'freetrial':
          case 'business':
            this.renderer.removeClass(this.header.nativeElement, 'interior-page');
            break;
          case 'careers':
            this.renderer.addClass(this.header.nativeElement, 'interior-page');
            this.renderer.addClass(this.header.nativeElement, 'careers');
            break;
          default:
            this.renderer.addClass(this.header.nativeElement, 'interior-page');
        }
      }
    });
    this.subscriptions.add(sub);
  }

  ngOnInit() {
    const sub = this.router.events
      .pipe(filter((event: NavigationEvent): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const baseUrl = event.urlAfterRedirects.split('?')[0];

        this.workFromHome = baseUrl === '/work-from-home';
        this.affiliates = baseUrl === '/affiliates';
        this.business = baseUrl === '/business';
      });
    this.subscriptions.add(sub);

    this.activatedRoute.queryParamMap.subscribe(params => {
      // Check if the 'landing' query parameter exists and its value is '1'
      if (params.has('landing') && params.get('landing') === '1') {
        this.HideMenu = true;
      } else {
        this.HideMenu = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (document.body.scrollTop > 130 || document.documentElement.scrollTop > 130) {
        this.renderer.addClass(this.header.nativeElement, 'show-header');
      } else {
        this.renderer.removeClass(this.header.nativeElement, 'show-header');
      }
    }
  }

  showOffCanvasMenu = false;
  // toggleMenu() {
  //   this.showOffCanvasMenu = !this.showOffCanvasMenu;
  //   if (this.showOffCanvasMenu) {
  //     this.renderer.addClass(this.document.body, 'menu-open');
  //     this.menuOpen = true;
  //   } else {
  //     this.renderer.removeClass(this.document.body, 'menu-open');
  //     this.menuOpen = false;
  //   }
  // }
  toggleMenu() {
    if (isPlatformBrowser(this.platformId)) {
      this.showOffCanvasMenu = !this.showOffCanvasMenu;
      if (this.showOffCanvasMenu) {
        this.renderer.addClass(this.document.body, 'menu-open');
        this.menuOpen = true;
        setTimeout(() => {
          const firstMenuItem = this.document.querySelector('.nav-items a');
          if (firstMenuItem) {
            (firstMenuItem as HTMLElement).focus();
          }
        });
      } else {
        this.renderer.removeClass(this.document.body, 'menu-open');
        this.menuOpen = false;
        const menuButton = this.document.getElementById('menu-button');
        if (menuButton) {
          (menuButton as HTMLElement).focus();
        }
      }
    }
  }

  logIn(event: Event) {
    const target = event.currentTarget as HTMLButtonElement;
    openCustomerAppWithQueryParams('login', target.id);
  }

  signUp(event: Event) {
    const target = event.currentTarget as HTMLButtonElement;
    openCustomerAppWithQueryParams('signup', target.id);
  }

  signUpPro() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = environment.dynamicLinks.laundryPro;
    }
  }
  signUpAffiliates() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = 'https://app.impact.com/advertiser-advertiser-info/Poplin.brand';
    }
  }

  getApp() {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = environment.dynamicLinks.customer;
    }
  }

  async getStartedBusiness() {
    await this.businessComponent.getStarted();
  }
}
