import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AccordionHeaders, Panel } from '../../../interfaces/common';

@Component({
  standalone: true,
  selector: 'app-nested-accordion',
  templateUrl: './nested-accordion.component.html',
  styleUrls: ['./nested-accordion.component.scss'],
  imports: [CommonModule, IonicModule],
})
export class NestedAccordionComponent {
  @Input() faqs: boolean;
  @Input() home: boolean;
  @Input() headers: AccordionHeaders = { header: '', subHeader: '' };
  @Input() nested: boolean;

  @Input() content: Panel[] = [
    {
      tab: '',
      panel: '',
    },
  ];

  toggleTab(event: MouseEvent): void {
    const header = (event.target as HTMLElement).closest('.accordion-header') as HTMLElement;
    const content = header.nextElementSibling as HTMLElement;
    const isOpening = !content.classList.contains('open');
    content.classList.toggle('open');
    header.classList.toggle('open');

    const icon = header.querySelector('.toggle-icon') as HTMLElement;
    if (isOpening) {
      icon.classList.add('open');
    } else {
      icon.classList.remove('open');
    }

    if (!isOpening) {
      const nestedContent = content.querySelectorAll('.accordion-content');
      nestedContent.forEach(element => {
        element.classList.remove('open');
        const prevElement = element.previousElementSibling;
        if (prevElement) {
          const nestedIcons = prevElement.querySelectorAll('.toggle-icon');
          nestedIcons.forEach(nestedIcon => nestedIcon.classList.remove('open'));
        }
      });
    }
  }

  genUniqueId(index: number, element: HTMLElement) {
    if (!element) {
      return '';
    }

    if (!element.id) {
      const randomNum = Math.floor(Math.random() * 100000);
      return `nested-accordion-${index}-` + randomNum + '-button';
    }
    return element.id;
  }
}
