export const environment = {
  version: '4.00.0',
  production: false,
  useEmulators: false,
  apiPath: 'https://non-prod-1ip7g17v.uc.gateway.dev',
  apiToken: 'AIzaSyD8M4LgHF7aOoF3C8jxvY_pqq_m3h9UflM',
  firebase: {
    projectId: 'sudsharetest',
    appId: '1:119070405931:web:a12e04e952565a7fce016e',
    databaseURL: 'https://sudsharetest.firebaseio.com',
    storageBucket: 'sudsharetest.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAwRhEpguV8SUdiBp0TcEkCLkVJ93wJhGs',
    authDomain: 'sudsharetest.firebaseapp.com',
    messagingSenderId: '119070405931',
    measurementId: 'G-Q0PEX44PPZ',
  },
  contentful: {
    spaceId: 'ummby9ihu4il',
    accessToken: 'SXLUvDJr8MPnZCRkWRfW8aQE9ASh4bxEQaYwoOZzjGE',
    environment: 'master', // replace with your environment ID if needed - master is the default production environment in Contentful
  },
  apps: {
    customer: 'https://nonprod-app.poplin.co',
    laundryPro: 'https://nonprod-laundry-pro.poplin.co',
  },
  dynamicLinks: {
    customer: 'https://link.nonprod.poplin.co/app',
    laundryPro: 'https://link.nonprod.poplin.co/app-lp',
  },
  googleTagManager: {
    appId: 'GTM-PPMZ9PHL',
  },
  loginLinks: {
    customer: 'https://nonprod-app.poplin.co/?originalUrl=https:%2F%2Fnonprod-poplin.co&website=true&login=1',
  },
  preSignUp: {
    customer: 'https://nonprod-app.poplin.co/pre-signup',
    laundryPro: 'https://nonprod-laundry-pro.poplin.co/',
  },
  referralTerms: {
    refTermDoc: 'https://nonprod.poplin.co/blog/referral-terms',
  },
  statsig: {
    clientKey: 'client-isdIevPr1XKO4G4Cb9KSj7qCnPAZ9GYUYlAS8hFPsdS',
    experiments: {
      activeTest: 'hero_image',
    },
    params: {
      LayoutType: 'LayoutType',
    },
  },
};
