<div class="download-app-container">
  <a id="poplin-home-link" href="/">
    <img src="assets/images/logo-black.svg" alt="Poplin Logo" />
  </a>
  <a id="ios-app-store-link" href="https://itunes.apple.com/us/app/sudshare-for-sudsters/id1333729162"
    ><img src="assets/images/ico-app-store.webp" alt="IOS App Store"
  /></a>
  <a id="google-play-store-link" href="https://play.google.com/store/apps/details?id=com.sudshare.sudster"
    ><img src="assets/images/ico-google-play.webp" alt="Google Play Store"
  /></a>
</div>
