<div class="banner">
  <p #marquee>
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities •
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities •
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities •
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities •
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities •
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities •
    Nationwide personal laundry service in over 500 cities • Nationwide personal laundry service in over 500 cities
  </p>
</div>
