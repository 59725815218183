import { CommonModule, ViewportScroller } from '@angular/common';
import {
  APP_ID,
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  ErrorHandler,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { SkipToContentComponent } from './components/common/skip-to-content/skip-to-content.component';
import { BusinessComponent } from './pages/business/business.component';
import { ErrorHandlingService } from './services';
import { PlatformId } from './types/common';
import { storeUTMCodesInCookie } from './util/cookie-tracking';

const webOnlyProviders = [];
if (typeof window !== 'undefined' && !/localhost/.test(globalThis.location.origin)) {
  webOnlyProviders.push(
    ServiceWorkerModule.register('service-workers.js', {
      registrationStrategy: 'registerWhenStable:30000',
    })
  );
}

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [CommonModule, HeaderComponent, FooterComponent, SkipToContentComponent, RouterModule, webOnlyProviders],
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    { provide: APP_ID, useValue: 'serverApp' },
    ScreenTrackingService,
    UserTrackingService,
    BusinessComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'poplin-homepage';
  private renderer: Renderer2;
  constructor(
    private viewportScroller: ViewportScroller,
    private elementRef: ElementRef,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: PlatformId,
    rendererFactory: RendererFactory2
  ) {
    this.platformId = platformId;
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.injectZendeskScript();
    this.injectAttentiveScript();
    storeUTMCodesInCookie();
  }

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      const scrollData = localStorage.getItem('scrollData');

      if (scrollData) {
        const scrollDataObject = JSON.parse(scrollData);
        const storedScrollPosition = scrollDataObject.scrollPosition;
        const storedPathName = scrollDataObject.pathname;
        const elementId = scrollDataObject.elementId;
        if (storedScrollPosition && storedPathName === window.location.pathname && elementId) {
          const interval = setInterval(() => {
            if (this.elementRef.nativeElement.querySelector(`#${elementId}`)) {
              this.viewportScroller.scrollToPosition([0, parseInt(storedScrollPosition, 10)]);
              localStorage.removeItem('scrollData');
              clearInterval(interval);
            }
          }, 150);
          setTimeout(() => {
            if (interval) {
              clearInterval(interval);
            }
            if (!this.elementRef.nativeElement.querySelector(`#${elementId}`)) {
              this.viewportScroller.scrollToPosition([0, parseInt(storedScrollPosition, 10)]);
              localStorage.removeItem('scrollData');
            }
          }, 1000);
        } else {
          localStorage.removeItem('scrollData');
        }
      }
    }
  }

  private injectTrustPilotScript() {
    const TPScript = document.createElement('script');
    TPScript.type = 'text/javascript';
    TPScript.async = true;
    TPScript.src = '/assets/scripts/tp.widget.bootstrap.min.js';
    document.head.appendChild(TPScript);
  }

  injectZendeskScript(): void {
    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://static.zdassets.com/ekr/snippet.js?key=956bb62e-8eb8-428f-b6bd-1fc0cc54d362';
    s.id = 'ze-snippet';
    this.renderer.appendChild(document.body, s);
  }

  private injectAttentiveScript(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.attn.tv/poplin/dtag.js';
    script.id = 'attentive-cdn';
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }
}
