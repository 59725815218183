import { CommonModule } from '@angular/common';
import { Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from '../../../services/api/api.service';
import { DialogService } from '../../../services/dialog/dialog.service';

@Component({
  standalone: true,
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss'],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
})
export class ContactSalesComponent {
  @ViewChild('loadingText') loadingText: ElementRef;
  @Input() previousModalId: string;
  contactForm: FormGroup;
  lastKeyPressed: string;
  loading = false;

  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private injector: Injector
  ) {
    this.contactForm = this.formBuilder.group({
      estimatedPounds: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      fullName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, this.phoneNumberValidator()]],
    });
  }

  phoneNumberValidator(): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
      const valid = regex.test(control.value);
      return !valid ? { invalidPhoneNumber: { value: control.value } } : null;
    };
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      spinner: 'circles',
      mode: 'ios',
    });
    return await loading.present();
  }

  async onSubmit() {
    if (this.contactForm.valid) {
      this.loading = true;
      this.presentLoading();
      const contactForm = this.contactForm.value;
      contactForm.estimatedPounds = parseInt(contactForm.estimatedPounds);
      this.apiService.post('/general/v1/business-form', contactForm).subscribe(
        async res => {
          if (res.statusCode === 200) {
            // success
            this.loading = false;
            await this.loadingController.dismiss();
            await this.modalController.dismiss({ formSubmitted: true });
          } else {
            // error
            this.loading = false;
            await this.loadingController.dismiss();
          }
        },
        async err => {
          this.loading = false;
          await this.loadingController.dismiss();
          this.showErrorAlert(err.message);
        }
      );
    }
  }

  formatPhoneNumber(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const cleaned = inputElement.value.replace(/\D/g, '');

    // Extract area code, first 3 digits, and last 4 digits
    const areaCode = cleaned.slice(0, 3);
    const firstThreeDigits = cleaned.slice(3, 6);
    const lastFourDigits = cleaned.slice(6, 10);
    const rawValue = inputElement.value;

    // Check if the last input was a backspace or delete
    if (this.lastKeyPressed === 'Backspace' || this.lastKeyPressed === 'Delete') {
      let newRawValue = rawValue;

      // Remove the formatting characters, if necessary
      if (newRawValue.slice(-1) === ')') {
        newRawValue = newRawValue.slice(0, -1);
      } else if (newRawValue.slice(-1) === ' ') {
        newRawValue = newRawValue.slice(0, -2);
      } else if (newRawValue.slice(-1) === '-') {
        newRawValue = newRawValue.slice(0, -1);
      } else if (newRawValue.slice(-1) === '(') {
        newRawValue = newRawValue.slice(0, -1);
      }
      this.contactForm.controls['phone'].setValue(newRawValue);
      inputElement.value = newRawValue;
      return;
    }

    // Build the formatted phone number
    let formattedNumber = '';
    if (areaCode) {
      formattedNumber += `(${areaCode}`;
      if (areaCode.length === 3) {
        formattedNumber += ') ';
      }
    }

    if (firstThreeDigits) {
      formattedNumber += firstThreeDigits;
      if (firstThreeDigits.length === 3) {
        formattedNumber += '-';
      }
    }

    formattedNumber += lastFourDigits;
    this.contactForm.controls['phone'].setValue(formattedNumber);
    return;
  }

  storeLastKeyPressed(event: KeyboardEvent) {
    this.lastKeyPressed = event.key;
  }

  handlePounds(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Remove any non-digit characters from the input value
    const sanitizedValue = value.replace(/\D/g, '');

    // Update the input value with the sanitized version
    input.value = sanitizedValue;

    // Update the form control's value with the sanitized value
    this.contactForm.get('estimatedPounds')?.setValue(sanitizedValue);
  }

  private async showErrorAlert(message: string): Promise<void> {
    const dialogService: DialogService = this.injector.get<DialogService>(DialogService);
    await dialogService.showErrorAlert({ message });
  }
}
