<div class="love-language">
  <div class="contained-content header-content">
    <h2>Laundry Pros</h2>
    <div class="headlines">
      <div>Laundry is</div>
      <div>our love language</div>
    </div>
    <div class="copy-container">
      <div class="divider"></div>
      <p class="our-fleet" *ngIf="!business">
        For our fleet of Laundry Pros, being delicate with delicates is a natural instinct. And overstuffing a dryer? A
        personal offense!
      </p>
      <p class="our-fleet" *ngIf="business">
        For our fleet of Laundry Pros, folding fitted sheets is a breeze. And overstuffing a dryer? A personal offense!
      </p>
    </div>
  </div>
  <div class="love-language-carousel">
    <app-carousel>
      <div class="keen-slider__slide" *ngFor="let testimonial of testimonials">
        <div class="slide-content">
          <app-profile [testimonials]="true" [laundryPro]="true" [card]="testimonial"></app-profile>
        </div>
      </div>
    </app-carousel>
  </div>
  <ul class="contained-content content-list">
    <li>
      <h3>Starting at</h3>
      <div class="headlines">
        <div>$1/pound</div>
      </div>
    </li>
    <li>
      <h3>How much does laundry weigh?</h3>
      <h4>1 hamper &asymp; 10 pounds</h4>
    </li>
    <li>
      <div class="headlines">
        <div>enjoy free pickup</div>
        <div>&amp; delivery</div>
      </div>
    </li>
  </ul>
</div>
