<div class="footer">
  <div class="links-and-giveback">
    <ul class="links">
      <li>
        <a id="root-link" routerLink="/">
          <span class="sr-only">Poplin</span>
        </a>
      </li>
      <li><a id="about-link" routerLink="/about">About Us</a></li>
      <li><a id="contact-link" routerLink="/contact">Contact</a></li>
      <li><a id="gift-link" routerLink="/gift-cards">Gift Cards</a></li>
      <li><a id="wfh-link" routerLink="/work-from-home">Laundry Pros</a></li>
      <li><a id="cml-link" routerLink="/business">For Business</a></li>
      <li><a id="affiliates-link" routerLink="/affiliates">Affiliates</a></li>
      <li><a id="cml-link" routerLink="/blog" queryParamsHandling="preserve">Blog</a></li>
      <li><a id="career-link" routerLink="/careers">Careers</a></li>
      <li><a id="press-link" routerLink="/press-releases">Press</a></li>
      <li class="divider"></li>
      <li><a id="swag-link" href="https://store.poplin.co" target="_blank">Store</a></li>
      <li class="divider"></li>
      <li><a id="login-link" href="javascript:void(0)" (click)="logIn($event)">Log In</a></li>
    </ul>
    <div class="giveback">
      <h2>Community Giveback</h2>
      <h3>The Giving Cycle</h3>
      <p>
        Laundry isn't the only thing piling up. A portion of every Poplin order goes to complimentary laundry services
        for community members in need.
      </p>
      <ul>
        <li>
          <a id="fb-link" href="https://www.facebook.com/hello.poplin" title="Poplin Facebook Page" target="_blank"
            >Facebook</a
          >
        </li>
        <li>
          <a id="yt-link" href="https://www.youtube.com/@poplin_co" title="Poplin YouTube Channel" target="_blank"
            >YouTube</a
          >
        </li>
        <li>
          <a
            id="insta-link"
            href="https://www.instagram.com/hello_poplin/"
            title="Poplin Instagram Account"
            target="_blank"
            >Instagram</a
          >
        </li>
        <li>
          <a id="twitter-link" href="https://twitter.com/poplin_co" title="Poplin Twitter Account" target="_blank"
            >Twitter</a
          >
        </li>
        <li>
          <a
            id="linkedin-link"
            href="https://www.linkedin.com/company/poplin-co"
            title="Poplin LinkedIn Account"
            target="_blank"
            >LinkedIn</a
          >
        </li>
      </ul>
      <div class="trustbox-statement">
        <p>
          See our
          <a class="trustbox-link" href="https://www.trustpilot.com/review/poplin.co" target="_blank">4,700+ reviews</a>
          on
        </p>
        <img src="/assets/images/Trustpilot_white.webp" alt="" />
      </div>
    </div>
  </div>
  <div class="bottom-footer-container">
    <ul class="policy">
      <li><a id="privacy-link" routerLink="/privacy-policy">Privacy Policy</a></li>
      <li><a id="terms-link" routerLink="/terms-of-service">Terms of service</a></li>
    </ul>
    <ul class="app-stores">
      <li>
        <a id="apple-link" href="https://apps.apple.com/us/app/sudshare/id1333675995" rel="sponsored">
          <img
            src="assets/images/ico-app-store.webp"
            alt="Apple Store link to download Poplin app"
            aria-hidden="true" />
          <span class="sr-only">Download on the App Store</span>
        </a>
      </li>
      <li>
        <a id="google-link" href="https://play.google.com/store/apps/details?id=com.sudshare.sudshare" rel="sponsored">
          <img
            src="assets/images/ico-google-play.webp"
            alt="Google Play link to download Poplin app"
            aria-hidden="true" />
          <span class="sr-only">Get in on Google Play</span>
        </a>
      </li>
    </ul>
  </div>
</div>
