<header #mainHeader class="main-header" [ngClass]="{ 'show-menu': showOffCanvasMenu }">
  <div>
    <div class="menu-button-container">
      <ion-button
        id="menu-button"
        [class.border]="menuOpen"
        class="large menu-button"
        (click)="toggleMenu()"
        aria-label="Toggle navigation menu"
        [hidden]="HideMenu"
        tabindex="0">
        <div class="hamburger">
          <div></div>
        </div>
      </ion-button>
      <ion-button
        *ngIf="!workFromHome"
        id="header-login-button"
        class="login-button"
        (click)="logIn($event)"
        tabindex="0"
        role="link">
        Log in
      </ion-button>
    </div>
    <div>
      <a class="logo-link" aria-label="Poplin homepage" id="logo-link" routerLink="/" tabindex="0">
        <svg width="69" height="37" viewBox="0 0 69 37" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 7.37956C0 3.3208 3.33741 0 7.46753 0C11.5977 0 14.9408 3.3208 14.9408 7.37389C14.9408 11.4326 11.5804 14.7535 7.47328 14.7535C5.49151 14.7535 3.68781 13.9814 2.3494 12.7326V27.5485H0V7.37956ZM2.34366 7.37956C2.34366 10.1611 4.63562 12.4374 7.46753 12.4374C10.3052 12.4374 12.5914 10.1781 12.5914 7.37956C12.5914 4.59804 10.2822 2.32172 7.46753 2.32172C4.65285 2.32172 2.34366 4.59804 2.34366 7.37956ZM46.1494 1.70865H43.8V27.5485H46.1494V1.70865ZM17.4396 13.357C13.3094 13.357 9.97202 16.6778 9.97202 20.7366C9.97202 24.7953 13.3324 28.1161 17.4396 28.1161C21.5467 28.1161 24.9071 24.7953 24.9071 20.7366C24.9071 16.6778 21.5467 13.357 17.4396 13.357ZM17.4396 25.7944C14.6076 25.7944 12.3157 23.5181 12.3157 20.7366C12.3157 17.955 14.6249 15.6787 17.4396 15.6787C20.2542 15.6787 22.5634 17.955 22.5634 20.7366C22.5634 23.5181 20.2715 25.7944 17.4396 25.7944ZM49.0043 14.4299H51.3537V27.5485H49.0043V14.4299ZM51.3537 9.93403H49.0043V12.2955H51.3537V9.93403ZM34.4311 13.357C30.301 13.357 26.9635 16.6778 26.9635 20.7366V37H29.3129V26.0953C30.6514 27.3441 32.4551 28.1161 34.4368 28.1161C38.544 28.1161 41.9044 24.7953 41.9044 20.7366C41.8986 16.6778 38.5382 13.357 34.4311 13.357ZM34.4311 25.7944C31.5992 25.7944 29.3072 23.5181 29.3072 20.7366C29.3072 17.955 31.6164 15.6787 34.4311 15.6787C37.2458 15.6787 39.555 17.955 39.555 20.7366C39.555 23.5181 37.2458 25.7944 34.4311 25.7944ZM54.0534 20.7593C54.0534 16.7005 57.4138 13.3797 61.521 13.3797C65.6339 13.3797 68.9943 16.6778 69 20.7536V27.5485H66.6506V20.7536C66.6506 17.9721 64.3414 15.6958 61.5267 15.6958C58.712 15.6958 56.4029 17.9551 56.4029 20.7536V27.5542H54.0534V20.7593Z" />
        </svg>
      </a>
    </div>
    <div>
      <ion-button
        role="link"
        *ngIf="!workFromHome && !business && !affiliates"
        id="header-request-pickup-button"
        class="large continue"
        (click)="signUp($event)"
        role="button"
        type="button"
        tabindex="0">
        <span>Create Account</span>
        <div class="btn-icon" slot="end">
          <ion-icon src="assets/images/ico-arrow-right.svg" role="presentation"></ion-icon>
        </div>
      </ion-button>
      <ion-button
        id="pro-wash-get-started-button"
        *ngIf="business"
        class="large continue"
        (click)="getStartedBusiness()"
        tabindex="-1">
        Get Started
        <div class="btn-icon" aria-hidden="true">
          <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </ion-button>
      <ion-button
        *ngIf="workFromHome && !business"
        id="header-request-pickup-button"
        class="large continue"
        (click)="signUpPro()"
        tabindex="-1">
        <span>BE A LAUNDRY PRO</span>
        <div class="btn-icon" slot="end">
          <ion-icon src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </ion-button>
      <ion-button
        role="link"
        *ngIf="affiliates"
        id="header-request-pickup-button"
        role="link"
        class="large continue"
        (click)="signUpAffiliates()"
        tabindex="-1">
        <span>START EARNING</span>
        <div class="btn-icon" slot="end">
          <ion-icon src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </ion-button>
    </div>
  </div>
  <nav
    class="nav"
    [ngClass]="{ 'show-menu': showOffCanvasMenu }"
    aria-labelledby="nav-heading"
    [attr.aria-hidden]="!showOffCanvasMenu">
    <span id="nav-heading" class="sr-only">Poplin navigation menu</span>
    <div class="nav-body">
      <div class="nav-items">
        <a
          id="home-link"
          routerLink="/"
          aria-label="Go to the Poplin website home page"
          (click)="toggleMenu()"
          tabindex="-1">
          <img src="assets/images/logo-white.svg" alt="Poplin Logo" />
        </a>
        <ul (click)="toggleMenu()">
          <li><a id="about-link" routerLink="/about" [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'">About</a></li>
          <li><a id="store-link" href="/#FAQSection" [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'">FAQS</a></li>
          <li>
            <a id="contact-link" routerLink="/contact" [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'">Contact</a>
          </li>
          <li>
            <a id="gift-link" routerLink="/gift-cards" [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'">Gift Cards</a>
          </li>
          <li>
            <a id="wfh-link" routerLink="/work-from-home" [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'"
              >Laundry Pros</a
            >
          </li>
          <li>
            <a
              id="wfh-link"
              routerLink="/blog"
              queryParamsHandling="preserve"
              [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'"
              >Blog</a
            >
          </li>
          <li>
            <a id="career-link" routerLink="/careers" [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'">Careers</a>
          </li>
          <li>
            <a
              id="store-link"
              href="https://store.poplin.co"
              target="_blank"
              [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'"
              >Store</a
            >
          </li>
          <li class="divider"></li>
          <li>
            <a
              id="login-link"
              href="javascript:void(0)"
              (click)="logIn($event)"
              [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'"
              >Log In</a
            >
          </li>
        </ul>
      </div>
      <!-- End .nav-items -->
      <div class="nav-footer">
        <ion-button
          (click)="getApp()"
          id="get-app-button"
          class="outline-secondary block large"
          [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'"
          >Get the app</ion-button
        >
        <ion-button
          (click)="signUp($event)"
          id="footer-request-pickup-button"
          class="continue block"
          [attr.tabindex]="showOffCanvasMenu ? '0' : '-1'">
          <span>Create Account</span>
          <div class="btn-icon" slot="end">
            <ion-icon src="assets/images/ico-arrow-right.svg"></ion-icon>
          </div>
        </ion-button>
      </div>
      <!-- End .nav-footer -->
    </div>
  </nav>
</header>
<div class="overlay" (click)="toggleMenu()" [ngClass]="{ 'show-overlay': showOffCanvasMenu }"></div>
