/* eslint-disable @typescript-eslint/no-explicit-any */

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { DialogService, LoggingService } from '..';
import { environment } from '../../../environments/environment';

const LOG_TAG = '[ErrorHandlingService]';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService implements ErrorHandler {
  constructor(private injector: Injector, private logger: LoggingService) {}

  handleError(error: unknown): void {
    console.log('error', error);
    this.handle(error);
  }

  private async handle(error: unknown): Promise<void> {
    try {
      const message = this.getMessageFromUnknownError(error);
      await this.showErrorAlert(message);
    } catch (errorHandlerError) {
      this.logger.logError(LOG_TAG, 'Internal Exception:', errorHandlerError);
    }
  }

  private getMessageFromUnknownError(error: unknown): string {
    let message = 'An unknown error has occurred.';
    if (error instanceof Object && 'rejection' in error) {
      error = (error as any).rejection;
    }
    if (error instanceof Error && error.message) {
      message = error.message;
    }
    return message;
  }

  private async showErrorAlert(message: string): Promise<void> {
    if (!environment.production) {
      const dialogService: DialogService = this.injector.get<DialogService>(DialogService);
      await dialogService.showErrorAlert({ message });
    }
  }
}
