import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Entry } from 'contentful';
import { LazyImgDirective } from 'src/app/directives/image.directive';
import { PlatformId } from 'src/app/types/common';
import { TypePressReleasesFields } from '../../../services/contentful/content-types';
import { ContentService } from '../../../services/contentful/content.service';

@Component({
  standalone: true,
  imports: [CommonModule, LazyImgDirective],
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss'],
})
export class PressComponent implements OnInit {
  pressReleases: Entry<TypePressReleasesFields>[] | undefined;

  constructor(
    private titleService: Title,
    private meta: Meta,
    public contentService: ContentService,
    @Inject(PLATFORM_ID) private platformId: PlatformId
  ) {
    this.meta.updateTag({ name: 'title', content: 'Poplin in the Media' });
    this.meta.updateTag({
      name: 'description',
      content: 'Discover the latest buzz surrounding Poplin! Check out features on ABC, Yahoo, USA Today, and more.',
    });
    this.meta.updateTag({ name: 'keywords', content: 'media, press release, poplin' });
    this.titleService.setTitle('Poplin in the Media');
  }

  ngOnInit(): void {
    this.getPressReleases();
  }

  getPressReleases() {
    this.contentService.getPressReleases().subscribe({
      next: entry => {
        this.pressReleases = entry.items;
      },
    });
  }
}
