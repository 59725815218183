<div class="interior-page-content press-page">
  <h1>In the News</h1>
  <div>
    <div *ngFor="let article of pressReleases">
      <a href="{{ article.fields.link }}" title="{{ article.fields.title }}" target="_blank">
        <img src="{{ article.fields.image.fields.file.url }}" alt="{{ article.fields.imageDescription }}" />
        <span>
          <h3>{{ article.fields.title }}</h3>
        </span>
        <img class="press-logo" src="{{ article.fields.logo.fields.file.url }}" alt="Press Logo" />
      </a>
    </div>
  </div>
  <br />
  <h3 style="text-align: center">
    For media inquiries, please contact <a href="mailto:press@poplin.co">press&#64;poplin.co</a>
  </h3>
</div>
