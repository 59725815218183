import { environment } from '../../environments/environment';

export const openCustomerAppWithQueryParams = (location: string, id?: string) => {
  const queryParams = window.location.search;
  const baseUrl = window.location.origin;

  const scrollPosition = window.scrollY;
  const pathname = window.location.pathname;

  const scrollData = {
    scrollPosition: scrollPosition.toString(),
    pathname: pathname,
    elementId: id || '',
  };

  const abId = localStorage.getItem('abId');

  localStorage.setItem('scrollData', JSON.stringify(scrollData));

  if (location == 'login') {
    return queryParams.includes('utm_') || queryParams.includes('referredby') || queryParams.includes('referralId')
      ? window.open(
          `${environment.apps.customer}/${queryParams}&originalUrl=${encodeURIComponent(
            baseUrl
          )}&abId=${abId}&website=true&login=1`,
          '_self'
        )
      : window.open(
          `${environment.apps.customer}/?&originalUrl=${encodeURIComponent(baseUrl)}&abId=${abId}&website=true&login=1`,
          '_self'
        );
  } else {
    return queryParams.includes('utm_') || queryParams.includes('referredby') || queryParams.includes('referralId')
      ? window.open(
          `${environment.apps.customer}/${queryParams}&originalUrl=${encodeURIComponent(
            baseUrl
          )}&abId=${abId}&website=true`,
          '_self'
        )
      : window.open(
          `${environment.apps.customer}/?originalUrl=${encodeURIComponent(baseUrl)}&abId=${abId}&website=true`,
          '_self'
        );
  }
};
