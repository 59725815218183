import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyImgDirective } from 'src/app/directives/image.directive';
import { PlatformId } from 'src/app/types/common';
import { Card } from '../../../interfaces/common';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, LazyImgDirective],
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() benefits: boolean;
  @Input() testimonials: boolean;
  @Input() laundryPro: boolean;
  @Input() workFromHome: boolean;
  @Input() customerTestimonials: boolean;

  @Input() imageCover: boolean;
  @Input() business: boolean;
  @Input() card: Card = {
    name: '',
    city: '',
    rating: '',
    showQuote: false,
    quote: '',
    quoteAuthor: '',
    picture: '',
    date: '',
    showProBadge: false,
    loadNumber: 0,
    showName: true,
    amount: '',
  };

  name = '';
  city = '';
  rating = '';
  showQuote = true;
  quote = '';
  quoteAuthor = '';
  picture = '';
  date = '';
  showProBadge = false;
  loadNumber = 24;
  showName = true;
  amount = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public mobile: any;

  constructor(@Inject(PLATFORM_ID) private platformId: PlatformId) {}

  @HostListener('window:resize', ['$event'])
  ngOnInit(): void {
    if (this.card) {
      this.name = this.card.name || '';
      this.city = this.card.city ? this.card.city.toUpperCase() : '';
      this.rating = this.card.rating || '';
      this.showQuote = this.card.showQuote || false;
      this.quote = this.card.quote || '';
      this.quoteAuthor = this.card.quoteAuthor || '';
      this.picture = this.card.picture || '';
      this.date = this.card.date || '';
      this.showProBadge = this.card.showProBadge || false;
      this.loadNumber = this.card.loadNumber || 0;
      this.showName = this.card.showName === false ? false : true;
      this.amount = this.card.amount || '';
    }
    if (isPlatformBrowser(this.platformId)) {
      this.checkSize();
    }
  }

  checkSize() {
    this.mobile = window.innerWidth < 768;
  }
}
