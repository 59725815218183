<div class="promo-panel" [ngClass]="classes" [ngStyle]="setStyles()">
  <div class="img-container">
    <img *ngIf="hero && !business" src="assets/images/logo-white.svg" alt="Poplin Logo" />
    <img *ngIf="hero && business" src="assets/images/business-logo-white.svg" alt="Poplin Logo" />
  </div>
  <div class="container container_banner" *ngIf="isBrowser">
    <div class="headlines">
      <div *ngFor="let text of content">{{ text }}</div>
    </div>
    <ion-button
      id="promo-poplin-sign-up-button"
      class="large continue"
      *ngIf="showButton && !launderer && !showGetStarted && !affiliates"
      (click)="signUp($event)">
      Create Account
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
    <ion-button
      id="promo-poplin-sign-up-button"
      class="large continue"
      *ngIf="showButton && affiliates"
      (click)="signUpAffiliates()">
      Start Earning
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
    <ion-button
      id="promo-pro-download-app-button"
      class="large continue"
      *ngIf="showButton && launderer"
      (click)="proDownloadApp()">
      Get Started Now
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
    <ion-button
      id="promo-get-started-business-button"
      class="large continue"
      *ngIf="showButton && showGetStarted"
      (click)="getStartedBusiness()">
      Get Started
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
  </div>
</div>

