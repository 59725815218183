import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { LazyImgDirective } from 'src/app/directives/image.directive';
import { PlatformId } from 'src/app/types/common';
import { BusinessComponent } from '../../../pages/business/business.component';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, LazyImgDirective],
  selector: 'app-pro-wash',
  templateUrl: './pro-wash.component.html',
  styleUrls: ['./pro-wash.component.scss'],
})
export class ProWashComponent implements OnInit, AfterViewInit {
  constructor(private businessComponent: BusinessComponent, @Inject(PLATFORM_ID) private platformId: PlatformId) {}
  @Input() headlinesContent: Array<string> = [];
  @Input() byLine = '';
  @Input() showStats = false;
  @Input() showButton = true;
  @Input() showImageTiles = true;
  @Input() about: boolean;
  @Input() rumpled: boolean;
  @Input() home: boolean;
  @Input() showGetStarted = false;
  @Input() business = false;

  @ViewChild('video') video: ElementRef;
  showVideo = false;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const userAgentString = navigator.userAgent;
      const chromeAgent = userAgentString.indexOf('Chrome') > -1;
      let safariAgent = userAgentString.indexOf('Safari') > -1;

      if (chromeAgent && safariAgent) safariAgent = false;
      if (!safariAgent && !this.detectDevice()) {
        this.showVideo = true;
      }
    }
  }

  ngAfterViewInit() {
    if (this.video && this.showVideo) {
      if (this.video.nativeElement) {
        this.video.nativeElement.muted = true;
        this.video.nativeElement.play();
      }
    }
  }

  detectDevice() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  signUp(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    openCustomerAppWithQueryParams('signup', target.id);
  }

  async getStartedBusiness() {
    await this.businessComponent.getStarted();
  }
}
