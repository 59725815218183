import { Injectable } from '@angular/core';
import Statsig, { DynamicConfig } from 'statsig-js';

@Injectable({
  providedIn: 'root',
})
export class StatsigService {
  getExperiment(name: string): DynamicConfig {
    return Statsig.getExperiment(name);
  }
  checkGate(name: string) {
    return Statsig.checkGate(name);
  }
}
