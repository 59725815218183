import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import Statsig from 'statsig-js';
import { v4 as uuidv4 } from 'uuid';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

const randomId = localStorage.getItem('abId') ?? saveId();

function saveId() {
  const newId = uuidv4();
  localStorage.setItem('abId', newId);
  return newId;
}

Statsig.initialize(
  environment.statsig.clientKey,
  { customIDs: { abId: randomId } },
  {
    environment: { tier: environment.production ? 'Prod' : 'NonProd' },
  }
)
  .catch(error => {
    if (isDevMode()) console.error('Statsig Fails', error);
  })
  .finally(() => {
    bootstrapApplication(AppComponent, appConfig).catch((err: any) => console.error(err));
  });
