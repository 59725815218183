export class ApiResponse {
  success: boolean;
  statusCode: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any> | string | null;

  constructor(success: boolean, statusCode: number, data = null) {
    this.success = success;
    this.statusCode = statusCode;
    this.data = data;
  }
}
