/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

class LoggingLevel {
  public static Info = 'Info';
  public static Warnings = 'Warnings';
  public static Errors = 'Errors';
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  logInfo(tagName: string, message: any, ...optionalParams: any[]) {
    this.log(tagName, message, LoggingLevel.Info, optionalParams);
  }

  logWarning(tagName: string, message: any, ...optionalParams: any[]) {
    this.log(tagName, message, LoggingLevel.Warnings, optionalParams);
  }

  logError(tagName: string, message: any, ...optionalParams: any[]) {
    this.log(tagName, message, LoggingLevel.Errors, optionalParams);
  }

  private log(tagName: string, message: any, level = LoggingLevel.Warnings, ...optionalParams: any[]) {
    if (!environment.production) {
      switch (level) {
        case LoggingLevel.Errors:
          console.error(`%c${tagName}`, 'color: red; font-weight:bold;', message, ...optionalParams);
          break;
        case LoggingLevel.Warnings:
          console.warn(`%c${tagName}`, 'color: yellow; font-weight:bold;', message, ...optionalParams);
          break;
        case LoggingLevel.Info:
          console.info(`%c${tagName}`, 'color: green; font-weight:bold;', message, ...optionalParams);
          break;
        default:
          console.debug(`%c${tagName}`, 'color: grey; font-weight:bold;', message, ...optionalParams);
      }
    }
  }
}
