<div class="accordion-container">
  <div
    class="responsive"
    [class.not-nested-responsive]="!nested"
    [class.nested-responsive]="nested"
    [class.faq-block]="faqs">
    <div *ngIf="!nested" class="header">
      <h4>{{ headers.header }}</h4>
      <h5>{{ headers.subHeader }}</h5>
    </div>

    <div class="accordion contained-content-smaller">
      <div class="accordion-panel" *ngFor="let item of content; let i = index">
        <div
          #button
          [attr.id]="genUniqueId(i, button)"
          class="accordion-header"
          tabindex="0"
          (click)="toggleTab($event)"
          *ngIf="nested">
          <span class="tab-title">{{ item.tab }}</span>
          <div class="toggle-icon">
            <svg class="toggle-icon__isClosed" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="var(--color-content-primary)"
                d="M63.9948 37C63.9948 36.4477 63.547 36 62.9948 36L56.9948 36C56.4425 36 55.9948 36.4477 55.9948 37V56H37C36.4477 56 36 56.4477 36 57V63C36 63.5523 36.4477 64 37 64H55.9948V83C55.9948 83.5523 56.4425 84 56.9948 84H62.9947C63.547 84 63.9947 83.5523 63.9947 83V64H83C83.5523 64 84 63.5523 84 63V57C84 56.4477 83.5523 56 83 56H63.9948V37Z" />
            </svg>
            <svg class="toggle-icon__isOpen" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect fill="var(--color-content-primary)" x="40" y="57" width="40" height="6" rx="3" />
            </svg>
          </div>
        </div>
        <div
          #button
          [attr.id]="genUniqueId(i, button)"
          class="accordion-header"
          tabindex="0"
          (click)="toggleTab($event)"
          *ngIf="!nested">
          <div class="icon-tab-container">
            <ion-icon class="tab-icon" src="{{ item.icon }}"></ion-icon>
            <span class="tab-title">{{ item.tab }}</span>
          </div>
          <div class="toggle-icon">
            <svg class="toggle-icon__isClosed" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="var(--color-content-primary)"
                d="M63.9948 37C63.9948 36.4477 63.547 36 62.9948 36L56.9948 36C56.4425 36 55.9948 36.4477 55.9948 37V56H37C36.4477 56 36 56.4477 36 57V63C36 63.5523 36.4477 64 37 64H55.9948V83C55.9948 83.5523 56.4425 84 56.9948 84H62.9947C63.547 84 63.9947 83.5523 63.9947 83V64H83C83.5523 64 84 63.5523 84 63V57C84 56.4477 83.5523 56 83 56H63.9948V37Z" />
            </svg>
            <svg class="toggle-icon__isOpen" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect fill="var(--color-content-primary)" x="40" y="57" width="40" height="6" rx="3" />
            </svg>
          </div>
        </div>
        <div class="accordion-content" [class.accordion-content--text]="!item.nestedTabs">
          <ng-container *ngIf="!item.nestedTabs">
            <div>
              <div [innerHTML]="item.panel"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.nestedTabs">
            <app-nested-accordion [nested]="true" [content]="item.nestedTabs"></app-nested-accordion>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
