<div class="confirm-container">
  <div>
    <h2>Thank you!</h2>
    <p>An account representative will be in touch within 24 hours.</p>
    <ion-button
      id="confirm-modal-dismiss-button"
      (click)="modalController.dismiss()"
      class="button"
      color="dark"
      expand="block"
      >Done</ion-button
    >
  </div>
</div>
