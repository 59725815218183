<div class="contact-container">
  <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <span class="dismiss" id="close-modal-button" (click)="modalController.dismiss()">&#10005;</span>
    <h2 class="header">Contact Us to Get Started</h2>
    <ion-grid class="grid">
      <ion-row>
        <ion-col>
          <ion-item lines="none" class="input-container">
            <ion-input
              id="sales-pounds-input"
              class="input"
              label="Estimate LBS of laundry/week"
              labelPlacement="floating"
              (input)="handlePounds($event)"
              type="tel"
              ngDefaultControl
              formControlName="estimatedPounds"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-item lines="none" class="input-container">
            <ion-input
              id="sales-name-input"
              class="input"
              label="Full Name"
              labelPlacement="floating"
              autocomplete="name"
              type="text"
              ngDefaultControl
              formControlName="fullName"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-item lines="none" class="input-container">
            <ion-input
              id="sales-email-input"
              class="input"
              label="Work Email"
              labelPlacement="floating"
              autocomplete="email"
              type="email"
              ngDefaultControl
              formControlName="email"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-item lines="none" class="input-container">
            <ion-input
              id="sales-phone-number-input"
              class="input"
              label="Phone Number"
              labelPlacement="floating"
              autocomplete="tel"
              type="tel"
              ngDefaultControl
              (keydown)="storeLastKeyPressed($event)"
              (input)="formatPhoneNumber($event)"
              formControlName="phone"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-button
      class="submit-button"
      id="submit-sales-form-button"
      expand="block"
      type="submit"
      color="dark"
      [disabled]="contactForm.invalid">
      Request a Consultation
    </ion-button>
    <button id="sales-modal-signup-button" (click)="modalController.dismiss({ signUp: true })" class="sign-up">
      SIGN UP INSTEAD
    </button>
  </form>
</div>
