export const faqs = [
  {
    icon: '../../../../assets/images/shirts_custom.svg',
    tab: 'The Service',
    nestedTabs: [
      {
        tab: "What's included?",
        panel:
          "Poplin's personal laundry service finishes your laundry to perfection.<br><br><ul><li>Professionally washed</li><li>Flawlessly dried</li><li>Expertly folded</li></ul><br>Also includes free pickup and delivery, custom options like hang-dry, and <a href='https://poplin.co/blog/poplin-protection-plan' target='_blank'>Poplin's Protection Plan</a> covering you in case of damage or loss.",
      },
      {
        tab: 'Do you separate lights & darks?',
        panel:
          'Yes, of course. You just tap and order. Everything else is taken care of for you. Our Laundry Pros will separate your lights and darks and tame even your most unruly, rumpled piles into pristine stacks of perfectly folded laundry. All you have to do is get dressed.',
      },
      {
        tab: "What if there's loss or damage?",
        panel:
          "You and your belongings are safe with Poplin.<br><br><strong>Poplin's Protection Plan</strong><br> It’s extremely rare that items are lost or damaged. Your laundry is tagged, kept together, and separate from other orders at all times. Laundry is washed in cold water (unless you request otherwise). Whites and colors are separated and clothes are dried in normal heat or hang-dry, based on your request. Your Laundry Pro will use a standard washer and dryer. There is no part of the laundering process that is unique to what you would do at home and that would cause damage or loss. However, in the rare event items are lost or damaged you’re protected up to $1000 per order under <a href='https://poplin.co/blog/poplin-protection-plan' target='_blank'>Poplin's Protection Plan</a>.<br><br><strong>ID Verified</strong><br>Our verification system cross-references user information, government ID, and more to confirm the identity of all Laundry Pros.<br><br><strong>Background Cleared</strong><br>Our comprehensive background check authenticates that every Laundry Pro is a good, decent law-abiding citizen.<br><br><strong>AI Screened</strong><br>We deploy AI technology that analyzes over 1 billion identities in a cross-merchant dataset as well as hundreds of factors to block bad-actors from entering the platform.<br><br><strong>Interests Aligned</strong><br>Poplin’s algorithm ranks Laundry Pros based on dozens of performance metrics, including customer ratings, and distributes jobs in order of rank. Most customers are serviced by top ranked Laundry Pros.",
      },
    ],
  },
  {
    icon: '../../../../assets/images/medal_custom.svg',
    tab: 'Care of Laundry',
    nestedTabs: [
      {
        tab: 'Detergent',
        panel:
          'When placing an order, you have 3 detergent options:<br><br><ol><li>Premium-scented</li><li>Hypoallergenic (unscented/sensitive skin)</li><li>Provide your own</li></ol><br>If you choose premium-scented or hypoallergenic, your Laundry Pro will use a top-rated premium brand detergent.<br><br>If you choose “provide your own,” please include your detergent with your pickup. Your Laundry Pro will use your detergent and return any unused detergent to you.',
      },
      {
        tab: 'Dry Temperature',
        panel: 'Poplin Laundry Pros dry on low-medium heat; never high heat.',
      },
      {
        tab: 'Fabric Softener',
        panel:
          'Fabric softener is not recommended because it damages fabrics and shortens the life of clothes; therefore, Poplin Laundry Pros do not generally inventory or use fabric softener. However, if you want fabric softener simply request it in “special instructions” and provide the fabric softener you want and it’ll be done for you. There is no extra fee for this.',
      },
      {
        tab: 'Hang Dry',
        panel:
          "For Poplin’s <strong>Standard</strong> (next-day) laundry service, your Laundry Pro is happy to accommodate requests for items to be hung dry.<br><br>Hang dry is not available for Poplin’s <strong>Express</strong> (same-day or overnight) service.<br><br>For Poplin’s <strong>Standard</strong> (next-day) service, if you want items hung dry:<br><br><ol><li >Select “hang dry” and “hangers.”</li><li >Put your hang-dry items in a separate bag and label the bag “hang dry.”</li><li >Put the correct number of hangers in your hang-dry bag. (We do not stock hangers.)</li></ol><br>That's it. We'll hang dry those items and return them to you hung and dry. There's no extra charge for this service, but please be aware that hang dry items are weighed after they are washed, dried, hung, and packed, which means they get weighed with the hangers.",
      },
      {
        tab: 'Hot or Cold Wash?',
        panel:
          "We launder clothes in cold water, <a href='https://www.apartmenttherapy.com/washing-clothes-in-cold-water-vs-hot-water-35648166' target='_blank'>as recommended by laundry experts</a>. However, if you want us to launder in hot water simply request it in “special instructions” and we’ll accommodate you.",
      },
      {
        tab: 'Other Laundry Products',
        panel:
          "Poplin's Laundry Pros only inventory detergent, which is included with your wash-dry-fold service; however, you can include any laundry products you want used with your service (for example fabric softener, dryer sheets, bleach, etc.) and we'll use what you provide, as per your instructions, and return what is not used to you. There is no additional charge for this.",
      },
      {
        tab: 'Other Services',
        panel:
          "Poplin is a wash-dry-fold personal laundry service.<br><br>Poplin's Laundry Pros do not look for stains, treat stains, or use any stain removal products.<br><br>Poplin's Laundry Pros do not offer additional services like ironing, lint removal, shoe cleaning, hand washing, or dry cleaning.<br><br>Poplin's Laundry Pros do offer a “hang dry” option.",
      },
      {
        tab: 'Pack by Family Member',
        panel:
          "Poplin's Laundry Pros try their best to pack laundry by family member and item type, so it's easy for you to put your laundry away. Feel free to include any special requests in “special instructions” and/or message your Laundry Pro.",
      },
    ],
  },
  {
    icon: '../../../../assets/images/dollar_custom.svg',
    tab: 'Pricing & Payment',
    nestedTabs: [
      {
        tab: 'Price',
        panel:
          'Poplin’s <strong>Standard</strong> (next-day) laundry service is $1/LB in most zip codes, higher in some. Poplin’s <strong>Express</strong> (same-day or overnight) laundry service is $2/LB in most zip codes, higher in some. Pickup and delivery is free.',
      },
      {
        tab: 'Cost per Bag?',
        panel:
          'There’s no per-bag charge; you are charged per pound of laundry. The number of bags you provide does not change the per pound price. In fact, smaller bags are better for our Laundry Pros!<br><br>Poplin’s <strong>Standard</strong> (next-day) laundry service is $1/LB in most zip codes, higher in some. Poplin’s <strong>Express</strong> (same-day or overnight) laundry service is $2/LB in most zip codes, higher in some. Pickup and delivery is free.',
      },
      {
        tab: 'Estimated Cost',
        panel:
          'Poplin’s <strong>Standard</strong> (next-day) laundry service is $1/LB in most zip codes, higher in some. Poplin’s <strong>Express</strong> (same-day or overnight) laundry service is $2/LB in most zip codes, higher in some. Pickup and delivery is free.<br><br>An average hamper of clothes weighs about 10 pounds. That might help you estimate the cost of your service. Or, simply weigh your laundry using a bathroom scale. First weigh yourself. Then weigh yourself holding each bag of laundry. Subtract one from the other and this will give you the weight of your laundry.<br><br>Most households send 20-60 pounds of laundry per week. Curious about what 40 pounds looks like? <a href="https://poplin.zendesk.com/hc/en-us/articles/20180039556891">See photos of different laundry amounts here.</a>',
      },
      {
        tab: 'Forms of Payment',
        panel:
          "Poplin accepts credit cards and debit cards.<br><br>Poplin does not accept cash, and our Laundry Pros are not allowed to accept cash either.<br><br>We also don't accept PayPal.",
      },
      {
        tab: 'Gift Cards',
        panel:
          "To purchase gift cards for Poplin services, please go to <a href='https://poplin.co/gift-cards' target='_blank'>Poplin.co/gift-cards</a>",
      },
      {
        tab: 'How to verify weight',
        panel:
          "To verify the weight of your laundry simply weigh the bags after they're delivered.<br><br>You can easily weigh the bags at home with a standard bathroom scale. First weigh just yourself, then again while holding each bag. Subtract one from the other and you'll get the weight of each bag of laundry.",
      },
      {
        tab: 'Large Items ($8 Extra)',
        panel:
          "Large items, like comforters, are an EXTRA $8 per item (that's in addition to the cost per pound). A large item is one that requires its own wash/dry cycle in a standard sized residential washer/dryer.",
      },
      {
        tab: 'Minimum Fee',
        panel:
          'Poplin’s minimum order charge depends on your area and the service type selected. You will clearly see the minimum charges for your area prior to placing your order.',
      },
      {
        tab: 'Preauthorization',
        panel:
          "When you place an order with Poplin, your credit/debit card is “pre-authorized.” The amount can vary and depends on your order history, the size of your order, and other factors. This preauthorization confirms that the funds are available on your credit/debit card and puts a “hold” on those funds. You are <u>not</u> charged this amount.<br><br>After your laundry is washed, dried, folded, packed, and weighed, your credit/debit card (or gift card or Poplin credit) is charged the cost of the service, and the “hold” amount is released.<br><br>If you cancel an order the “hold” amount is released.<br><br>Please note that Poplin releases the “hold” amount immediately, but it's your credit/debit card company that determines when those funds are available to you.<br><br>If the preauthorized amount is a problem for you, we recommend you use a credit card instead of a debit card. Credit cards hold the amount from your credit line rather than charging and refunding you.",
      },
      {
        tab: 'Receipt',
        panel:
          'After your laundry is washed, dried, folded, packed, and weighed, your credit/debit card will be charged for your service and you’ll be emailed a receipt.',
      },
      {
        tab: 'Weighed Wet or Dry?',
        panel:
          "Your laundry is weighed after it's dry. It's not weighed when it's wet.<br><br>We accept wet laundry, although please put them in small bags so that each bag isn't too heavy.<br><br>After your laundry is washed, dried, folded, packed, and weighed, your credit/debit card is charged for the cost of the service.",
      },
      {
        tab: 'Potential Fees',
        panel: `
          <section style="margin-bottom: 15px;"><div><strong>Trust and safety fee</strong></div>
            <div>To enable Poplin to operate a safe and secure network, we collect a small service fee of $3 on orders. You will clearly see this on your estimated cost breakdown.</div>
          </section>
          <section style="margin-bottom: 15px;">
            <div><strong>Surge pricing</strong></div>
            <div>This charge only applies if Poplin had to pay Laundry Pros incentives to accept your previous orders multiple times. Poplin incentivizes Laundry Pros to accept orders they might otherwise reject. Common reasons for needing extra incentives include:</div>
          </section>
          <section style="margin-bottom: 15px;">
            <ul style="margin: 15px 0;"><li>Additional requests that take more time</li><li>Small order size</li><li>Limited capacity for more orders</li></ul>
            <div>Surge fees are never charged without your prior approval.</div>
          </section>
          <section style="margin-bottom: 15px;">
            <div><strong>Missed pickup fee</strong></div>
            <div>A $10 fee is applied if your Laundry Pro arrives for pickup but your laundry is not at the designated pickup location.</div>
          </section>
          <section style="margin-bottom: 15px;">
            <div><strong>Unsanitary fee</strong></div>
            <div>A $20 fee may be charged if your laundry is deemed to be unsafe for Laundry Pros to service.</div>
          </section>

          <div><a href="https://poplin.zendesk.com/hc/en-us/articles/24436347827995-Potential-Fees"target="_blank">Learn more about potential fees.</a></div>`,
      },
    ],
  },
  {
    icon: '../../../../assets/images/directions_car.svg',
    tab: 'Pick-up/Drop-off',
    nestedTabs: [
      {
        tab: 'Time of Pickup/Drop off',
        panel:
          'Poplin offers standard service ($1/LB) and premium Express ($2/LB). The timing of pickups and drop-offs depends on the service speed you select.<br><br><strong>STANDARD SERVICE ($1/LB)</strong><br><br>Your pickup and delivery times depend on when you place your order:</br></br> <ul><li>Orders placed before 3pm are picked up before 8pm that day and delivered by 8pm the following day.</li><li>Orders placed after 3pm are picked up before 8pm the following day and delivered complete by 8pm one day after that. </li> </ul></br> Of course, Poplin’s Laundry Pros try to provide as prompt service as possible, so pick-up and drop-off often occurs well before our deadlines.<br><br>If your order is over 100 pounds your Laundry Pro may need an extra day to deliver.<br><br>Immediately after your order is assigned, your Laundry Pro will message you with an approximate pickup time, which is usually within a couple of hours of placing your order.<br><br>You can also message your Laundry Pro and inquire about their schedule or coordinate pick up and drop off times.<br><br><strong>EXPRESS SERVICE ($2/LB.)</strong><br><br>Express service deadlines vary depending on when your order is placed.</br></br><ul><li>Orders placed before 10am are picked up by 2pm and delivered by 8pm that evening.</li><li>Orders placed between 10am-3pm are picked up by 8pm and delivered by 10am the following morning.</li><li>Orders placed after 3pm are picked up by 2pm the following afternoon and delivered by 8pm that evening. However, Laundry Pros will do their best to pick up between 3-8pm the day the order is placed.</li></ul></br>Express service has a weight limit of 60 pounds and does not allow for hang-dry items. ',
      },
      {
        tab: 'Do I need laundry bags?',
        panel:
          "You can put your laundry out for pickup in any bag you want, disposable bags, or your hamper. Most people use disposable bags. If you use your hamper we'll transfer your clothes to our disposable bags and leave your hamper behind. You can also use laundry bags, which we'll return to you laundered.",
      },
      {
        tab: 'Free pickup/delivery',
        panel:
          "Poplin's laundry service includes free pick up and delivery (7 days a week). Just tell us where and we come to you.",
      },
      {
        tab: 'Hotel Guests',
        panel:
          'Poplin services hotel guests nationwide. The service provided and the ordering process is the same as if you were ordering from your home.<br><br>A few important points to note:<br><br><ol><li >Designate the “front desk” of your hotel as your pickup/dropoff location. We cannot pickup/dropoff from/to a hotel room. We service hotel guests through the front desk of the hotel only.<li >Make sure to label each bag with your name, “Poplin Pickup,” and the bag number (for example 1 of 3, 2 of 3, etc.). Proper labeling of your bags is important, as there are often multiple Poplin customers staying at the same hotel.</li></ol>',
      },
      {
        tab: 'Schedule a pick up',
        panel:
          'Poplin is an on-demand service, like UBER. In most cases your laundry is picked up shortly after you place an order, though this varies depending on the time your order is placed and the service speed selected. See the FAQs about pickup and drop-off for more details.<br><br>However, at this time, there is no way to “schedule” a pickup for a later date.',
      },
      {
        tab: "Can't leave laundry out",
        panel:
          "When ordering, most customers put their laundry at the designated pick up spot, and let us do the rest. It's incredibly easy and convenient. However, if you can’t leave your laundry out after you place your order you can still use Poplin. Here’s how we can accommodate you.<br><br><strong>OPTION 1 - RECEIVE A CALL OR MESSAGE BEFORE PICKUP/DELIVERY</strong><br><br>During the order process you'll be asked to provide a “pickup spot”. Choose “Custom”, then choose “Message me on arrival”. After you choose “Message me on arrival” you'll be presented with the option “Call me instead”. Feel free to choose one or the other.<br><br><strong>OPTION 2 - COORDINATE PICK UP WITH YOUR LAUNDRY PRO</strong><br><br>During the order process you'll be asked to provide a “pickup spot”. Choose “Simple.” You'll get a message from your Laundry Pro indicating a pickup time. Message your Laundry Pro back and let them know that your laundry will not be out and that he/she should knock/ring/message once they arrive, at which time you can hand-deliver your laundry. If your Laundry Pro's pickup/delivery time doesn't work for you then you can message them to agree upon a mutually convenient time.<br><br>In either case above, after you place your order you will be asked to put your laundry out for pickup. You may ignore that request.<br><br>Please note: You will be billed a surcharge fee of $10 if your Laundry Pro arrives for pickup and is not able to secure your laundry.",
      },
    ],
  },
  {
    icon: '../../../../assets/images/credit_card.svg',
    tab: 'Business Accounts',
    nestedTabs: [
      {
        tab: 'Business Accounts',
        panel:
          "Poplin services many types of business accounts. All the information in the Help Center, on our website, and in the app applies for business accounts as well as residential.<br><br>If you have over 500 pounds of laundry/week please <a href='https://poplin.zendesk.com/hc/en-us/requests/new' target='_blank'>contact us</a> so we can discuss how we can best service you and offer you a dedicated account manager.",
      },
    ],
  },
  {
    icon: '../../../../assets/images/laundry_custom.svg',
    tab: 'Limitations',
    nestedTabs: [
      {
        tab: 'Size Limitations',
        panel:
          'Poplin launders anything that fits in a standard sized residential washer and dryer.<br><br>Poplin cannot accommodate extra-large items, like king comforters. Any item we cannot launder will be returned in a separate bag, not laundered, not weighed, and no charge.',
      },
      {
        tab: 'Unsafe or Unsanitary Laundry',
        panel:
          'We do not service unsafe or unsanitary laundry. We also don’t service laundry that includes excessive pet hair.<br><br>Before you place an order you will be asked to certify that your laundry:<br><br><ul><li >is safe and does not include bed bugs, fleas, roaches, or biohazardous materials.</li><li >is sanitary and does not include feces, urine, blood, or vomit, beyond traces normally found in household laundry.</li><li >does not include excessive pet hair.</li></ul><br>Violators incur a $20 fee, laundry returned “as is,” and the account may be closed.',
      },
      {
        tab: 'Weight Limitations',
        panel:
          "For Poplin’s <strong>Standard</strong> (next-day) laundry service, there is no maximum amount of laundry we can do for you; however, if it's over 100 pounds we may need extra days to complete the work. Poplin’s <strong>Express</strong> (same-day or overnight) service, the weight limit is 60 pounds.",
      },
    ],
  },
];
export const faqHeader = {
  header: 'FAQS',
  subHeader: 'Want to know more?',
};
