<div class="pro-wash" [class.about-section]="about" [class.rumpled-wash]="rumpled">
  <div class="text-container">
    <div class="headlines" *ngIf="headlinesContent.length > 0">
      <div *ngFor="let headline of headlinesContent">{{ headline }}</div>
    </div>
    <h2 [class.home-h2]="home" [class.about-h2]="about" [innerHtml]="byLine"></h2>
    <ion-button
      id="pro-wash-sign-up-button"
      *ngIf="showButton && !showGetStarted"
      class="large contained"
      (click)="signUp($event)"
      role="button"
      type="button"
      tabindex="0">
      Create Account
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
    <ion-button
      id="pro-wash-get-started-button"
      *ngIf="showButton && showGetStarted"
      class="large continue"
      (click)="getStartedBusiness()"
      role="button"
      type="button"
      tabindex="0">
      Get Started
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
  </div>
  <div class="image-tiles">
    <ul
      aria-hidden="true"
      class="image-tiles"
      [class.ul-image]="!business"
      *ngIf="showImageTiles && !about && !business">
      <li>
        <img
          srcset="assets/images/bkg-stack-small.webp 133w, assets/images/bkg-stack.webp 444w"
          sizes="(max-width: 481px) 133px,
                      444px"
          src="assets/images/bkg-stack.webp"
          alt="Image of a stack of folded laundry" />
      </li>
      <li class="hoodie">
        <video *ngIf="showVideo" #video muted autoplay loop playsinline poster="assets/images/bkg-small-hoodie.webp">
          <source src="assets/images/bkg-small-hoodie.mp4" type="video/mp4" />
        </video>
        <img
          *ngIf="!showVideo"
          srcset="assets/images/bkg-small-hoodie.webp 133w, assets/images/bkg-small-hoodie.webp 444w"
          sizes="(max-width: 481px) 133px,
                    444px"
          src="assets/images/bkg-small-hoodie.webp"
          alt="Image of many shirts laid out" />
      </li>
      <li>
        <img
          srcset="assets/images/bkg-shirts-small.webp 133w, assets/images/bkg-shirts.webp 444w"
          sizes="(max-width: 481px) 133px,
                      444px"
          src="assets/images/bkg-stack.webp"
          alt="Image of many shirts laid out" />
      </li>
    </ul>
    <ul
      aria-hidden="true"
      class="image-tiles"
      [class.ul-image]="!business"
      [class.business]="business"
      *ngIf="showImageTiles && !about && business">
      <li>
        <img
          srcset="assets/images/bkg-stack-small.webp 133w, assets/images/bkg-stack.webp 444w"
          src="assets/images/bkg-stack.webp"
          alt="Image of a stack of folded laundry" />
      </li>
      <li class="hoodie">
        <img
          srcset="assets/images/white-towels-small.webp 133w, assets/images/white-towels.webp 444w"
          src="assets/images/white-towels.webp"
          alt="Image of a stack of white towels" />
      </li>
      <li>
        <img
          srcset="assets/images/bkg-shirts-small.webp 133w, assets/images/bkg-shirts.webp 444w"
          src="assets/images/bkg-stack.webp"
          alt="Image of many shirts laid out" />
      </li>
    </ul>
    <ul aria-hidden="true" class="image-tiles" [class.about]="about" *ngIf="showImageTiles && about">
      <li><img src="assets/images/About_Born1.webp" alt="Image of laundry being folded" /></li>
      <li class="hoodie">
        <img src="assets/images/About_Born2.webp" alt="Image of a stack of folded laundry" />
      </li>
      <li><img src="assets/images/About_Born3.webp" alt="Image of laundry being brushed" /></li>
    </ul>
  </div>
  <ul class="stats" *ngIf="showStats">
    <li class="wfh-launderers">115,000 work-from-home Laundry Pros nationwide</li>
    <li class="top-launderers">Top Laundry Pros earn over $5,000/month</li>
    <li class="folded">3 million loads washed &amp; folded each year</li>
  </ul>
</div>
