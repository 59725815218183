import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [CommonModule, RouterModule],
})
export class FooterComponent {
  logIn(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    openCustomerAppWithQueryParams('login', target.id);
  }
}
