<div id="preferences" class="preferences">
  <div *ngIf="!business" class="home">
    <h2>Preferences</h2>
    <h3>Particular about <br />your laundry? Us too.</h3>
    <ul id="dynamicTexts" class="dynamic-texts">
      <li id="one">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Delicate Wash?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="two">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>Done!</p>
      </li>
      <li id="three">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Hang dry?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="four">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>Happy to!</p>
      </li>
      <li id="five">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Hypoallergenic?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="six">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>Sure!</p>
      </li>
    </ul>
    <ion-button id="preferences-request-pickup-button" class="large continue" (click)="signUp($event)">
      Create Account
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
  </div>
  <div *ngIf="business" class="business">
    <h2>HOSPITALITY INSPIRED</h2>
    <h3>Need top notch service?<br />We're your laundry concierge.</h3>
    <ul id="dynamicTexts" class="dynamic-texts">
      <li id="one">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Account manager?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="two">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>Yes!</p>
      </li>
      <li id="three">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Multiple locations?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="four">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>Absolutely!</p>
      </li>
      <li id="five">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Got an app?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="six">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>We do!</p>
      </li>
      <li id="seven">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/customer_message.webp" alt="Image of message sender"
        /></span>
        <p>Track progress?</p>
        <span class="delivered">Delivered</span>
      </li>
      <li id="eight">
        <span aria-hidden="true" class="profile-img"
          ><img src="assets/images/pr-mia.webp" alt="Image of message sender"
        /></span>
        <p>Yup!</p>
      </li>
    </ul>
    <ion-button
      id="preferences-get-started-business-button"
      *ngIf="business"
      class="large continue"
      (click)="getStartedBusiness()">
      Get Started
      <div class="btn-icon" aria-hidden="true">
        <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
      </div>
    </ion-button>
  </div>
</div>
