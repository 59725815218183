import { UtmParams } from '../interfaces/common';

const utmParamsArray: (keyof UtmParams)[] = [
  'utm_source',
  'utm_term',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_irclickid',
  'utm_click_id',
  'referralId',
  'abId',
  'gclid',
  'gbraid',
  'wbraid',
  'FirebaseAppInstanceID',
  'fbclid',
  'fbc',
  'fbp',
];

function generateFacebookCookies(fbclid: string) {
  // Generate timestamp
  const timestamp = Math.floor(Date.now() / 1000);
  // Generate a random number for fbp
  const randomForFbp = Math.floor(Math.random() * 100000000000000);
  // Construct fbp value: "fb.1.timestamp.random"
  const fbp = `fb.1.${timestamp}.${randomForFbp}`;
  // Construct fbc value using fbclid: "fb.1.timestamp.fbclid"
  const fbc = `fb.1.${timestamp}.${fbclid}`;
  console.log(`[generateFacebookCookies] fbp: ${fbp}, fbc: ${fbc}`);
  return { fbp, fbc };
}

//This function is called in app.component.ts as part of initailization
export function storeUTMCodesInCookie(): void {
  // Function to set cookie with domain
  function setCookie(name: string, value: string, domain: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/' + '; domain=' + domain;
  }

  // Function to get URL parameters
  function getURLParameter(name: string): string | null {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(
          /\+/g,
          '%20'
        )
      ) || null
    );
  }

  // Check for UTM parameters and store them in cookies
  utmParamsArray.forEach(function (utmCode) {
    try {
      const utmValue = getURLParameter(utmCode);
      if (utmValue !== null) {
        //TODO: track down env variable for base_url so its not hardcoded
        setCookie(utmCode, utmValue, 'poplin.co', 30); // Store for 10 days
      }

      if (utmCode == 'fbclid' && utmValue !== null) {
        const { fbp, fbc } = generateFacebookCookies(utmValue);
        setCookie('fbp', fbp, 'poplin.co', 30);
        setCookie('fbc', fbc, 'poplin.co', 30);
      }
    } catch (err) {
      console.error(`[storeUTMCodesInCookie] unable to set cookie for ${utmCode}`);
    }
  });
}
