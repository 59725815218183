<div class="testimonials">
  <div class="testimonials-carousel">
    <app-carousel *ngIf="!business" role="region" aria-label="Testimonial - Laundry Pros">
      <div class="keen-slider__slide" role="tabpanel" *ngFor="let testimonial of testimonials">
        <div class="slide-content">
          <app-profile [customerTestimonials]="true" [testimonials]="true" [card]="testimonial"></app-profile>
        </div>
      </div>
    </app-carousel>
    <app-carousel *ngIf="business" role="region" aria-label="Testimonial - Laundry Pros">
      <div class="keen-slider__slide" role="tabpanel" *ngFor="let testimonial of businessTestimonials">
        <div class="slide-content">
          <app-profile [testimonials]="true" [business]="true" [card]="testimonial"></app-profile>
        </div>
      </div>
    </app-carousel>
  </div>
  <!-- TrustBox widget - Micro Review Count -->
  <div
    id="trustbox"
    class="trustpilot-widget"
    data-locale="en-US"
    data-template-id="5419b6a8b0d04a076446a9ad"
    data-businessunit-id="641cc035f896b23f2ab89b9f"
    data-style-height="24px"
    data-style-width="100%"
    data-theme="light"
    data-tags="SelectedReview"
    data-style-alignment="center">
    <a href="https://www.trustpilot.com/review/poplin.co" target="_blank" rel="noopener">Trustpilot</a>
  </div>
  <!-- End TrustBox widget -->
</div>
