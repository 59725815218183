<div class="check-area-panel" [class.faq-check]="faq">
  <div [hidden]="success || failure || serverFailure" class="check-your-area">
    <h2 *ngIf="!faq">Nationwide personal laundry service in over 500 cities</h2>
    <form id="zip-code-form" [formGroup]="zipCodeFormGroup">
      <ion-item lines="none">
        <div class="input-wrapper">
          <ion-input
            [class.input-faq]="faq"
            [class.check-area-input]="!faq"
            id="zip-code-input"
            type="text"
            placeholder="Type your zip code"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            value="zipCode"
            ngDefaultControl
            formControlName="ZipCode"
            (input)="handleChange($event)"
            (ionFocus)="onFocus()"
            (ionBlur)="onBlur()"
            *ngIf="!loading"></ion-input>
          <span class="vertical-line" [ngClass]="{ hidden: inputFocused || hideCursor }"></span>
        </div>
        <p #loadingText [class.checking-faq]="faq" class="loading" *ngIf="loading">Checking.</p>
      </ion-item>
      <ion-item
        no-lines
        *ngIf="zipCodeFormGroup.get('ZipCode')?.touched && zipCodeFormGroup.get('ZipCode')?.hasError('pattern')">
        <div class="zip-code-form-error">Please enter a valid zip code</div>
      </ion-item>

      <ion-button
        *ngIf="!faq"
        id="check-zip-button"
        class="large"
        type="submit"
        (click)="checkZipCode()"
        [disabled]="loading || emptyZipCode">
        Check your area
      </ion-button>
      <ion-button
        *ngIf="faq"
        id="check-zip-button"
        class="small"
        type="submit"
        (click)="checkZipCode()"
        [disabled]="loading || emptyZipCode">
        Check your area
      </ion-button>
    </form>
  </div>

  <div [hidden]="!success || failure || loading" class="check-your-area success">
    <h2>{{ zipCode }}</h2>
    <p [class.success-faq]="faq" [class.success-home]="!faq">Perfect, we're in your area!</p>
    <div class="zip-code-check-button-container">
      <ion-button *ngIf="!faq && !business" id="zip-code-log-in-button" class="large continue" (click)="signUp($event)">
        Create Account
        <div class="btn-icon" aria-hidden="true">
          <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </ion-button>
      <ion-button
        id="pro-wash-get-started-business-button"
        *ngIf="business"
        class="large continue"
        (click)="getStartedBusiness()">
        Get Started
        <div class="btn-icon" aria-hidden="true">
          <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </ion-button>
      <ion-button
        [class.be-a-laundry-pro]="faq"
        *ngIf="faq && !business"
        id="zip-code-log-in-button"
        class="small continue"
        (click)="signUpPro()">
        Be A Laundry Pro
        <div class="btn-icon" aria-hidden="true">
          <ion-icon slot="end" src="assets/images/ico-arrow-right.svg"></ion-icon>
        </div>
      </ion-button>
      <ion-button id="zip-code-reset-button" class="reset-check small" (click)="resetCheck()"
        >Check another Zip Code</ion-button
      >
    </div>
  </div>

  <div [hidden]="success || !failure" [class.failure-faq]="faq" class="check-your-area failure">
    <h2>{{ zipCode }}</h2>
    <p>Sorry, we're not in your area yet.</p>
    <ion-button id="zip-code-failure-reset-button" class="reset-check failure-reset" (click)="resetCheck()"
      >Check another Zip Code</ion-button
    >
  </div>
  <div [hidden]="success || failure || !serverFailure" [class.failure-faq]="faq" class="check-your-area traffic">
    <p>
      We're experiencing a high volume of traffic right now and our system is unable process your request. Please try
      again in a few moments.
    </p>
    <ion-button id="zip-code-failure-reset-button" class="reset-check failure-reset" (click)="resetCheck()"
      >Try Again</ion-button
    >
  </div>
</div>
