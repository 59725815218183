import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { PlatformId } from 'src/app/types/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['../../../../../node_modules/keen-slider/keen-slider.min.css', './carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId: PlatformId) {
    this.platformId = platformId;
  }
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;

  currentSlide = 0;
  dotHelper: Array<number> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slider: KeenSliderInstance = null as any;

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      setTimeout(() => {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, {
          initial: this.currentSlide,
          slideChanged: s => {
            this.currentSlide = s.track.details.rel;
          },
          mode: 'snap',
          loop: true,
          slides: {
            origin: 'center',
            perView: 'auto',
            spacing: 15,
          },
        });
        this.dotHelper = [...Array(this.slider.track.details.slides.length).keys()];
      }, 750);
    }
  }

  // ngOnDestroy() {
  //   if (this.slider) this.slider.destroy();
  // }
}
