/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiResponse } from './api-response';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = environment.apiPath;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': `${environment.apiToken}`,
    }),
    observe: 'response' as 'body',
  };

  public post(path: string, body: Record<string, any>): Observable<ApiResponse> {
    return this.http.post(this.apiURL + path, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => {
        return new ApiResponse(response['ok'], response['status'], response['body']);
      }),
      catchError(this.handleError)
    );
  }

  public put(path: string, body: Record<string, any>): Observable<ApiResponse> {
    return this.http.put(this.apiURL + path, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => {
        return new ApiResponse(response['ok'], response['status'], response['body']);
      }),
      catchError(this.handleError)
    );
  }

  public patch(path: string, body: Record<string, any>): Observable<ApiResponse> {
    return this.http.patch(this.apiURL + path, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => {
        return new ApiResponse(response['ok'], response['status'], response['body']);
      }),
      catchError(this.handleError)
    );
  }

  public delete(path: string): Observable<ApiResponse> {
    return this.http.delete(this.apiURL + path, this.httpOptions).pipe(
      map((response: any) => {
        return new ApiResponse(response['ok'], response['status'], response['body']);
      }),
      catchError(this.handleError)
    );
  }

  public get(path: string): Observable<ApiResponse> {
    return this.http.get(this.apiURL + path, this.httpOptions).pipe(
      map((response: any) => {
        return new ApiResponse(response['ok'], response['status'], response['body']);
      }),
      catchError(this.handleError)
    );
  }
  private handleError(error: HttpErrorResponse) {
    let message = 'An unknown error occurred, please try again later.';
    if (error.status === 0) {
      message = 'A network issue occurred, please try again later.';
    } else {
      message = `Error Code: ${error.status}, Message: ${error.error.message}`;
    }
    return throwError(() => new Error(message));
  }
}
