import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LazyImgDirective } from 'src/app/directives/image.directive';
import { BusinessComponent } from '../../../pages/business/business.component';
import { openCustomerAppWithQueryParams } from '../../../util';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, LazyImgDirective],
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferencesComponent implements OnDestroy {
  listener;
  distanceFromTop = 650;
  @Input() business: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private businessComponent: BusinessComponent
  ) {
    this.listener = this.renderer2.listen('window', 'scroll', () => {
      const elementOne = (document.getElementById('one') as Element).getBoundingClientRect().top;
      const elementTwo = (document.getElementById('two') as Element).getBoundingClientRect().top;
      const elementThree = (document.getElementById('three') as Element).getBoundingClientRect().top;
      const elementFour = (document.getElementById('four') as Element).getBoundingClientRect().top;
      const elementFive = (document.getElementById('five') as Element).getBoundingClientRect().top;
      const elementSix = (document.getElementById('six') as Element).getBoundingClientRect().top;
      const elementSeven = document.getElementById('seven')
        ? (document.getElementById('seven') as Element).getBoundingClientRect().top
        : '';
      const elementEight = document.getElementById('eight')
        ? (document.getElementById('eight') as Element).getBoundingClientRect().top
        : '';

      if (elementOne <= this.distanceFromTop) {
        const element = document.getElementById('one');
        if (element) {
          element.classList.add('show');
        }
      } else {
        const element = document.getElementById('one');
        if (element) {
          element.classList.remove('show');
        }
      }
      if (elementTwo <= this.distanceFromTop) {
        const element = document.getElementById('two');
        if (element) {
          element.classList.add('show');
        }
      } else {
        const element = document.getElementById('two');
        if (element) {
          element.classList.remove('show');
        }
      }
      if (elementThree <= this.distanceFromTop) {
        const element = document.getElementById('three');
        if (element) {
          element.classList.add('show');
        }
      } else {
        const element = document.getElementById('three');
        if (element) {
          element.classList.remove('show');
        }
      }
      if (elementFour <= this.distanceFromTop) {
        const element = document.getElementById('four');
        if (element) {
          element.classList.add('show');
        }
      } else {
        const element = document.getElementById('four');
        if (element) {
          element.classList.remove('show');
        }
      }
      if (elementFive <= this.distanceFromTop) {
        const element = document.getElementById('five');
        if (element) {
          element.classList.add('show');
        }
      } else {
        const element = document.getElementById('five');
        if (element) {
          element.classList.remove('show');
        }
      }
      if (elementSix <= this.distanceFromTop) {
        const element = document.getElementById('six');
        if (element) {
          element.classList.add('show');
        }
      } else {
        const element = document.getElementById('six');
        if (element) {
          element.classList.remove('show');
        }
      }
      if (elementSeven) {
        if (elementSeven <= this.distanceFromTop) {
          const element = document.getElementById('seven');
          if (element) {
            element.classList.add('show');
          }
        } else {
          const element = document.getElementById('seven');
          if (element) {
            element.classList.remove('show');
          }
        }
      }
      if (elementEight) {
        if (elementEight <= this.distanceFromTop) {
          const element = document.getElementById('eight');
          if (element) {
            element.classList.add('show');
          }
        } else {
          const element = document.getElementById('eight');
          if (element) {
            element.classList.remove('show');
          }
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.listener();
  }

  signUp(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    openCustomerAppWithQueryParams('signup', target.id);
  }

  async getStartedBusiness() {
    await this.businessComponent.getStarted();
  }
}
